<template>
<div>
  <ul class="tab-group">
    <li class="nav-item">
      <router-link class="nav-link" :to="{ name: 'CompanyAuditRecordList'}">
        <span>Company Audit Record</span>
      </router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="{ name: 'CompanyAuditDueByCompanyId' }">
        <span>Company Audit Due</span>
      </router-link>
    </li>
  </ul>
  <div class="col-12 pl-0">
    <router-view/>
  </div>
</div>
</template>

<script>
export default {
  name : 'CompanyAuditRecordMain'
}
</script>

<style scoped>
.tab-group {
  display: flex;
  width: 100%;
  margin-bottom: 0;
}

.tab-group > li {
  margin: .5rem 0;
  border-radius: 5px;
  list-style-type: none;

  &:hover, &:hover * {
    background-color: white;
    color: #d3313c;
  }
}

.e-btn-grp > li {
  list-style: none;
  font-size: 13px;
  padding: 4px;
  border-radius: 3px;
  color: #d3313c;
  cursor: pointer;

  &:hover {
    background-color: #fbeaec;
  }

  * {
    color: #d3313c;
  }
}

#ModelNav {
  padding: 0;

  li > a {
    padding: .5rem 1rem;
    color: #435464;
  }

  li:hover a {
    border-bottom: 2px solid #d3313c;
    color: #d3313c;
  }
}

.router-link-active {
  border-bottom: 2px solid #d3313c;
  color: #d3313c;

  * {
    color: #d3313c;
  }
}
</style>
